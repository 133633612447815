.hero {
  background: $grey;
  color: $green;
  overflow: hidden;
  //height: 450px;


  .hero-media {
    float:left;
    max-height: 450px;
    max-width: 100%;
  }
  .hero-media-50 {
    float:left;
    height: 100%;
    max-width: 50%;
  }
  .hero-body {
    display: flex;
    align-items: center;
    margin-left: 40px;
    height: 100%

    p {
      font-size: 26px;
      -webkit-font-smoothing: antaliased;
      margin: 0;
    }

    .hero-body-content{
      padding: 40px;
      width: 100%;

    }
  }
}
