//colors
$red: #923349;
$green: #B6DCB2;
$green-light: #CDE7CA;
$grey: #7a7a7a;
$grey-dark: #333;

//fonts
$font_sans: "europa", sans-serif;
$font_serif: "alegreya", serif;
