.panel {
  margin: 120px 0;

  blockquote {
    max-width: 1040px;
  }
}

.panel.side-by-side {
  display: flex;
  align-items: flex-start;
  max-width: 1040px;
  margin: 0 auto;

  .panel-header {
    flex-basis: 150px;
    margin-right: 60px;
  }

  .panel-content {
    flex: 1;

    p {
      font-size: 18px;
    }
  }
}
