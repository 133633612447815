body {
  background: #fff;
  font-family: $font_sans;
  color: $grey;
  font-weight: 300;
  line-height: 1.5;
}

a {
  cursor: pointer;
}

img {
  max-width: 100%;
}

p{
  font-size: 18px;
}
