.button {
  display: inline-block;
  cursor: pointer;
  font-size: 18ps;
  font-weight: 500;
  padding: 12px 24px;
  border-radius: 4px;
  text-align: center;
}

.navbar-default .navbar-nav > li > a.button{
  color: #fff;
  &:hover {
    background: mix(black, $red, 10%);
  }
}

.button.primary {
  background: $red;
  color: #fff;

  &:hover {
    background: mix(black, $red, 10%);
  }
}
