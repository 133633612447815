h1, h2, h3, h4, h5, h6 {
  color: $grey-dark;
  margin: 0;
  line-height: 1;
  font-weight: 400;
}

h1 {
  font-size: 40px;
  //letter-spacing: -.05em;
  font-family: sans-serif;
  font-weight: 600;
  color: $red;
  strong {
    color: $grey-dark;
    font-weight: 300;
  }
}

h2 {
  font: 20px $font_serif;
   }
h3 { font-size: 16px; }

a {
  color: $red;
  &:hover {
    color: $grey-dark;
    text-decoration: none;
  }
}

p {
  margin-top: 0;
}

blockquote {
 @include smooth;
  font-size: 24px;
  color: $red;
  font-family: $font_serif;

   footer {
     font-family: $font_sans;
     font-weight: 300;
     font-size: 14px;
     color: $grey-dark;
     margin: 0px 0 0 60px;
     text-transform: uppercase;
  }
}

.subhead {
  font: 11px $font_serif;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.normal {
  font-weight: normal !important;
}

sup {
  top: -0.25em;
}

.little-heading {
  font: 11px $font_serif;
  padding: 15px 0 0 20px;
  letter-spacing: .1em;
  text-transform: uppercase;
}
