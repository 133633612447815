nav.navbar.navbar-default {
    background-color: #fff;
    border: none;
}

.navbar-brand{
    height: auto;
}

.navbar-default .navbar-nav>li>a{
  color: $red;
}
.navbar-collapse {
  background: #fff;
  font-weight: 400;
  /*height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;


  .logo {
    flex: 1;
  }*/



  ul {
  //  display: flex;
  //  align-items: center;
    li {
      list-style: none;
  //    display: flex;
  //    align-items: center;
      &:not(:first-child){
    //    margin: 0 15px;
      }
    }
    a {
      text-decoration: none;
      font-size: 18px;
      color: $red;

      &:not(.button) {
        padding: 15px;
      }
    }
  }
}


@media (max-width: 760px) {

}
