.style-guide {
  background: #fafafa;
  padding: 60px 30px;
}

.component {
background: #fff;
//border: 1px solid #ddd;
//border-radius: 5px;
//padding: 30px;
margin: 10px 0;
position: relative;

  h6 {
    color: #999;
    position: absolute;
    top: 15px;
    right: 10px;
    margin: 0;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 4px;
      }

}
